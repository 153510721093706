@import './mixins/aspectRatio';
@import '../../styles/variables';

.CamPlaceholder {
  z-index: 20;
  @include aspect-ratio(16,9);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: color('gray', 40);
  pointer-events: none;
}

.spinnerContainer {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.loading {
  width: 50px;
  height: 50px;
  background-image: url('#{$cdn-logo-path}/surfline-loading-spinner.svg');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}

