@import '../../styles/variables';

.overlayWrapper {
  inset: 0;
  position: absolute;
  z-index: 1;
  display: none;
  pointer-events: none;

  &.show {
    display: block;
  }

  &:hover,
  &:focus,
  &:active {
    -webkit-tap-highlight-color: transparent;
    border: none;
    outline: none;
  }
}

.overlayContainer {
  pointer-events: none;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playPauseButton,
.skipButton {
  padding: 0;
  position: relative;
  z-index: 2;
  pointer-events: all;
}

.playPauseButton {
  height: 64px;
  margin: 20px;
  width: 64px;

  &:hover,
  &:focus,
  &:active {
    -webkit-tap-highlight-color: transparent;
    border: none;
    outline: none;
  }
}

.playPauseButtonBG {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &:hover,
  &:focus,
  &:active {
    -webkit-tap-highlight-color: transparent;
    border: none;
    outline: none;
  }
}

.skipButton {
  min-width: auto;
  width: 50px;
  height: 50px;
  color: $white;
  position: relative;
  font-size: 12px;
  font-weight: 500;

  span {
    position: relative;
    z-index: 1;
    top: 1px;
  }

  svg {
    position: absolute;
  }

  &:hover,
  &:focus,
  &:active {
    -webkit-tap-highlight-color: transparent;
    border: none;
    outline: none;
  }

  @media (max-width: $large-mobile) {
    height: 32px;
    width: 32px;
  }
}
