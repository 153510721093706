@import '../../styles/mixins';
@import '../../styles/variables';

.camErrorWrapper {
  @include aspect-ratio(16, 9);
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.camErrorContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.camErrorBackgroundImage {
  @include aspect-ratio(16, 9);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.camErrorContent {
  align-items: center;
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
}

.camErrorMessageWrapper {
  max-width: 540px;
  width: 90%;
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.camErrorText1,
.camErrorText2 {
  margin-bottom: 10px;
  color: $product-base;
}

.camErrorText2 {
  color: $product-base;
}

.camRewindLink {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 44px;
  width: 44px;

  &:hover {
    background-color: rgba(23, 23, 23, 0.3);
    border-radius: 5px;
    opacity: 1;
  }
}

@media (max-width: $large-mobile) {
  .camErrorText1 {
    font-size: 18px;
    line-height: 20px;
  }
  .camErrorText2 {
    font-size: 14px;
    line-height: 18px;
  }
}
