@import '../../../styles/variables';

@mixin cam-message {
  color: color('white');
  font-size: 21px;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;

  button {
    border: none;
    color: color('white');
    cursor: pointer;
    font-family: $product-font-linear;
    font-size: 14px;
    height: 34px;
    letter-spacing: 1.3px;
    margin: 15px 0px;
    outline: none;
    padding: 9px 15px 10px;
    text-align: center;
    text-transform: uppercase;
  }
}

@mixin cam-message-button($color, $tone) {
  $default-color: color($color, $tone);
  $darker-color: color($color, $tone + 20);
  $darkest-color: color($color, $tone + 40);

  button {
    border-top: 2px solid $default-color;
    background-color: #{$default-color};

    &:hover {
      border-top: 2px solid $darker-color;
      background-color: #{$darker-color};
    }

    &:active,
    &:focus {
      border-top: 2px solid $darkest-color;
      background-color: #{$darker-color};
    }
  }
}
