@use 'sass:math';

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: ' ';
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }
}

