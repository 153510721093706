@import '../../styles/variables';

.quiver-cam-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: 50%;
    box-sizing: content-box;
    background-color: color('gray', 40);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .7;
      background-image: radial-gradient(ellipse 100% 200% at 50% 50%, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 6%, rgba(255, 255, 255, 0.53) 100%);
      z-index: 1;
    }
  };
