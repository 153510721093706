@import '../../../styles/variables.scss';
@import '../mixins/camMessage';

.camTimeout {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.message {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
}

.button {
  margin: 0 auto;
}
