@import './mixins/aspectRatio';
@import './mixins/camMessage';

@import '../../styles/variables';

.quiver-cam-player {
  box-sizing: content-box;

  .ima-ad-container {
    left: 0px;
    position: absolute;
    top: 0px;
    /* Note: Important is required because the player css sets this to 1111,
    which puts the ads above of our global Footer CTA */
    z-index: 3 !important;
  }
}

.quiver-cam-player--hidden {
  display: none;
}

// START - AD NOT RUNNING
.quiver-cam-player--ad-not-running {
  .quiver-cam-player__player__pillWrapper {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
  }
  .quiver-cam-player__player__live,
  .quiver-cam-player__player__smartRewindsInsights {
    align-items: center;
    background: transparent;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    padding: 0;
    width: auto;
    z-index: 9;
    margin-right: 5px;
  }

  .quiver-cam-player__player__highlights-skip {
    z-index: 9;
    position: absolute;
    right: 15px;
    bottom: 15px;

    /* Typography/Callout 2 */

    font-family: $product-font-linear;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    text-align: right;

    /* Product/Extra Light */

    color: $product-extra-light;
  }

  .quiver-cam-player__player__fullscreen {
    z-index: 9;
    position: absolute;
    right: 8px;
    top: 4px;
    padding: 0;
    display: none;
    height: 44px;
    width: 44px;

    &__fullscreen-on {
      &::before {
        background-image: url('../../../public/images/cam-player/fullscreen.svg');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 18px;
        left: 13px;
        position: relative;
        width: 18px;
      }
    }

    &__fullscreen-off {
      &::before {
        background-image: url('../../../public/images/cam-player/fullscreen-exit.svg');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 18px;
        left: 13px;
        position: relative;
        width: 18px;
      }
    }

    @media (min-width: $tablet) {
      display: block;
    }
  }
}
// END - AD NOT RUNNING

// START: Prerecorded specific
.quiver-cam-player--prerecorded {
  .quiver-cam-player__player__recorded-earlier {
    border-radius: 2px;
    height: 12px;
    width: 145px;
    background: color('black');
    position: absolute;
    bottom: 13px;
    z-index: 10;
    left: 12px;
    padding: 4px 8px;
    color: color('white');
    font-family: $product-font-linear;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 12px;

    @media (min-width: $large-mobile) {
      font-size: 12px;
      width: 175px;
      padding: 6px 8px;
      bottom: 10px;
    }
  }
}
// END: Prerecorded specific

.quiver-cam-player--free {
  &__ad-free-popup {
    text-align: left;
    padding: 24px;
    background: color('white');
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 48px);
    z-index: 1000;
    border-radius: 0px;
    box-shadow: 0px -1px 7px 0px rgba(0, 0, 0, 0.16);

    h3 {
      font-family: $product-font-linear;
      margin: 0;
      letter-spacing: 0px;
      color: color('gray', 90);
      font-size: 22px;
      line-height: 22px;
    }
    p {
      font-family: $product-font-linear;
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      margin: 0;
      margin-top: 4px;
      margin-bottom: 20px;
      letter-spacing: 0px;
      color: color('gray', 90);
    }
    div {
      float: left;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0px;
      font-family: $product-font-linear;
      font-weight: bold;

      button {
        font-family: $product-font-linear;
        background-color: color('white');
        padding: 10px 22px;
        border: 1px solid color('gray', 55);
        color: color('gray', 50);
        font-weight: bold;
        display: block;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0px;
        cursor: pointer;
        outline: none;
        margin-right: 17px;
      }

      a {
        display: inline-block;
        padding: 11px 21px;
        border: none;
        color: color('white');
        background-color: color('green', 50);
        text-decoration: none;
        text-transform: uppercase;
        font-family: $product-font-linear;
        font-size: 14px;
        letter-spacing: 0px;
        cursor: pointer;
      }
    }
  }

  @media (min-width: $large-mobile) {
    &__ad-free-popup {
      width: 212px;
      height: 72px;
      bottom: 75px;
      left: 21px;
      box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.16);

      h3 {
        font-size: 20px;
        line-height: 20px;
      }
      div {
        button {
          margin-right: 18px;
          font-size: 12px;
          border: none;
          padding: 0;
        }
        a {
          color: color('green', 50);
          font-size: 12px;
          background: color('white');
          padding: 0;
        }
      }
    }
  }
}

.quiver-cam-player__player,
.quiver-ad-player__player,
.quiver-cam-error,
.quiver-cam-adblock,
.quiver-cam-nighttime {
  @include aspect-ratio(16, 9);
  position: relative;
  font-family: $product-font-linear;
}

.quiver-cam-player__player__overlays {
  position: absolute;
  bottom: 12px;
  left: 8px;
  z-index: 2;

  &__cam-title {
    font-family: $product-font-linear;
    color: color('white');
    font-size: 12px;
    letter-spacing: 0.3px;
    text-decoration: none;
    font-weight: 600;
    margin-top: 2px;
    text-align: left;
  }

  /* NOTE: Surfline/kbyg cam player uses DFP to manage styles */
  .quiver-cam-player__player__host-ad,
  .quiver-cam-player__player__host-ad a,
  .quiver-cam-player__player__host-ad span {
    font-family: $source-sans-pro;
    color: color('white');
    font-size: 13px;
    letter-spacing: 0.3px;
    text-decoration: none;

    iframe {
      width: 100%;
    }

    &:hover {
      text-decoration: underline;
    }

    @media (min-width: $tablet) {
      right: 16px;
    }
  }
  .quiver-cam-player__player__host-ad span {
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }
}

.quiver-cam-player__countdown {
  text-align: right;
  margin-bottom: 10px;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: color('gray', 50);
  height: 18px;
}

.quiver-cam-player__upsell-countdown {
  position: absolute;
  z-index: 1000;
  bottom: 5%;
  width: 100%;
  text-align: center;
  color: color('white');
  font-size: 14px;
  font-family: $product-font-linear;
  letter-spacing: -0.1px;
}

.quiver-cam-player__countdown {
  position: absolute;
  z-index: 3;
  color: color('white');
  font-size: 14px;
  font-family: $product-font-linear;
  bottom: 36px;
  top: unset;
  right: 15px;
  left: unset;
}

.quiver-cam-player__player__overlayControls {
  z-index: 1;
}

.quiver-cam-rewind-message {
  width: calc(100% - 20px);
  padding: 7px 10px;
  position: absolute;
  top: 0;
  z-index: 10;
  font-size: 12px;
  letter-spacing: 0.1px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
  color: color('white');

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  a {
    color: color('green');
    text-decoration: none;
    &:hover {
      color: color('green', 60);
    }
  }

  @media (min-width: $large-mobile) {
    width: auto;
    border-radius: 100px;
    top: 10px;
    right: 10px;
  }
}

.quiver-cam-rewind {
  width: 32px;
  padding-top: 10px;
  height: calc(32px - 10px);
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.1px;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  color: color('white');

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__text {
    opacity: 1;
    color: color('white');
    display: none;
    &:hover {
      color: color('white');
    }
  }

  .quiver-rewind-icon {
    display: inline-block;
    margin-right: 2px;
  }
}

.quiver-cam-upsell__message {
  @include cam-message;
  @include cam-message-button('green', 50);

  &__header {
    padding: 0 5px;
  }

  &__subtext {
    font-size: 14px;
    font-family: $product-font-linear;
    letter-spacing: -0.1px;

    a {
      text-decoration: none;
    }
  }
}

.quiver-cam-error__message {
  @include cam-message;
  font-family: $product-font-linear;
  font-size: 14px;
  padding: 0 20px;
  width: calc(100% - 40px);
  max-width: 540px;
  margin-top: 0px;
  text-align: center;

  svg {
    display: none;
  }

  &__text {
    padding: 0 0px;
  }

  &__subtext {
    font-size: 9px;
    line-height: 17px;
    padding: 0 0px;
  }

  @media (min-width: $large-mobile) {
    font-size: 18px;
    margin-top: 0px;
    width: calc(100% - 30px);
    line-height: 25px;

    svg {
      display: block;
      margin: auto;
      width: 80%;
    }

    &__text {
      clear: both;
      padding: 0 15px;
    }

    &__subtext {
      line-height: 18px;
      font-size: 12px;
      clear: both;
      margin: 6px 0;
      padding: 0 15px;
    }
  }

  @media (min-width: $tablet) {
    font-size: 22px;
    margin-top: 0px;
    width: calc(100% - 20px);
    line-height: 29px;

    svg {
      display: block;
      margin: auto;
    }

    &__text {
      clear: both;
      padding: 0 20px;
    }

    &__subtext {
      line-height: 21px;
      font-size: 15px;
      clear: both;
      margin: 10px 0;
      padding: 0 20px;
      letter-spacing: 0.04px;
    }
  }
}

.quiver-cam-nighttime__message {
  @include cam-message;
  position: absolute;
  margin: 10px auto;
  max-width: 420px;

  &__subtext {
    font-size: 14px;
    font-family: $product-font-linear;
    letter-spacing: -0.1px;

    a {
      text-decoration: none;
    }
  }
}

.quiver-cam-adblock-upsell__message {
  @include cam-message;
  @include cam-message-button('green', 50);
  position: absolute;
  margin: 10px auto;
  max-width: 420px;

  &__or {
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 9px;
    margin-bottom: 15px;

    span {
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 60%;
        width: 9999px;
        height: 1px;
        background: color('white');
        opacity: 0.1;
      }

      &:before {
        right: 100%;
        margin-right: 15px;
      }

      &:after {
        left: 100%;
        margin-left: 15px;
      }
    }
  }

  &__subtext {
    font-size: 14px;
    font-family: $product-font-linear;
    letter-spacing: -0.1px;
  }
}

@media (min-width: $large-mobile) {
  .quiver-cam-rewind {
    width: 130px;

    &__text {
      display: inline-block;
    }

    .quiver-rewind-icon {
      margin-right: 6px;
    }
  }
}

.quiver-cam-player {
  &--ad-not-running {
    .quiver-cam-player__countdown {
      bottom: 12px;
      top: unset;
      right: 15px;
      left: unset;
    }

    .quiver-cam-player__player__live {
      top: 10px;
      bottom: unset;
    }
  }
}
