@import '../../styles/variables';

.camPlayer {
  :global {
    @import './CamOverlay.scss';
    @import './CamPlayer.scss';
    @import './AspectRatioContent.scss';

    .quiver-cam-player__upsell-countdown {
      color: $product-secondary;
    }
  }

  &:hover {
    :global {
      .quiver-cam-player__player__overlays__cam-title {
        display: block;
      }
    }
  }
}

.upsellWrapper {
  :global {
    .sl-cam-midroll-upsell {
      z-index: 1;
    }
  }
}

.highlightsToggleContainer {
  left: 20px;
  position: absolute;
  top: 10px;
  z-index: 1;
}

.highlightsToggle {
  :global {
    .MuiSwitch-switchBase {
      &.Mui-checked {
        + .MuiSwitch-track {
          background-color: #{$brandSecondary}35;
        }
      }
    }

    .MuiSwitch-thumb {
      color: $white;

      &.Mui-checked {
        color: $white;
      }
    }

    .MuiSwitch-track {
      background-color: #{$brandSecondary}30;
    }
  }
}

.highlightsToggleLabel {
  color: $white;
  font-size: 12px;
}

.camhostAd a, .camhostAd span {
  font-family: $source-sans-pro;
  color: $white;
  font-size: 13px;
  letter-spacing: 0.2px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1px;
  margin-top: 13px;
  display: inline-block;
  font-weight: 600;
  text-align: left;
}